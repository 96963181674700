import React from 'react';
import App from './App';

import './index.scss';

const Web3MQ = () => {

  return (
    <App/>
  );
};

export default Web3MQ;
